import HttpClient from "../Framework/HttpClient";
import ApiConfiguration from "./Configuration";
import {z} from "zod";

export const ExoCustomerSchema = z.object({
    AccountNo: z.number(),
    Name: z.string().nullish(),
    Active: z.boolean(),
    Phone: z.string().nullish(),
    Email: z.string().nullish(),
    DeliveryAddress: z.array(z.string()).nullish(),
    Address: z.array(z.string()).nullish()
});

const ExoSalesOrderSchemaBase = z.object({
    Number: z.number(),
    Status: z.string(),
    OrderDate: z.coerce.date(),
    DueDate: z.coerce.date(),
    Customer: ExoCustomerSchema
});

export const ExoSalesOrderSchema = ExoSalesOrderSchemaBase.extend({
    Lines: z.array(z.lazy(() => ExoSalesOrderLineSchema)).nullish()
})

export const ExoSalesOrderLineSchema = z.object({
    Description: z.string().nullish(),
    StockCode: z.string().nullish(),
    DueDate: z.coerce.date(),
    QtyOrdered: z.number(),
    QtySupplied: z.number(),
    QtyInvoiced: z.number(),
    Order: ExoSalesOrderSchemaBase.nullish()
});

export const ExoWorksOrderSchema = z.object({
    Number: z.number(),
    SalesOrder: ExoSalesOrderSchemaBase.nullish(),
});

export type ExoSalesOrderBase = z.infer<typeof ExoSalesOrderSchemaBase>;
export type ExoCustomer = z.infer<typeof ExoCustomerSchema>;
export type ExoSalesOrderLine = z.infer<typeof ExoSalesOrderLineSchema>;

export default class ExoClient extends HttpClient {
    constructor() {
        super(ApiConfiguration.Exo);
    }
}