import {JobIncrement, JobRun} from "../Api/ApiClient";
import ApiClient from "../Api/ApiClient";
import {Table} from "react-bootstrap";
import QCMove from "./QCMove";

import {LoaderFunctionArgs, useLoaderData} from "react-router-dom";
import {IsUserInRole} from "../Framework/MsalClientApp";

interface PipeData {
    increment: JobIncrement,
    job: JobRun, 
    index: number | null
}

export async function loadPipe({ request, params } : LoaderFunctionArgs) {
    let { jobId, eventId } = params;
    let client = new ApiClient();
    let job = await client.GetJob(jobId!);
    let increment = await client.GetIncrement(jobId!, eventId!);
    let index = new URL(request.url).searchParams.get("i");
    return {
        job,
        increment,
        index: index ? parseInt(index) : null
    } as PipeData;
}

export default function PipeView() {
    //TODO: redirect to new pipe (from results)

    let {increment, job, index} = useLoaderData() as PipeData;
    
    const isAdmin = IsUserInRole(["Admin"]);
    
    const salesOrder = job.Job.SalesOrderLine?.Order
        ?? job.Job.SalesOrder
        ?? job.Job.WorksOrder?.SalesOrder;
    
    let props: {[index:string]:any} = {
        Date: increment.Timestamp.toDisplayDateTimeString(),
        Factory: job.Line.Factory.Name,
        Line: 'L' + job.Line.Name,
        Kilos: increment.Scrap?.Kilos?.toLocaleString(),
        //"Account No": customer?.AccountNo,

        //Coil labels are printed individually
        //Show quantity if qty > 1 and not a coil
        "Quantity": increment.Pipe && increment.Pipe.Quantity > 1 && !job.Job.SKU.IsCoil ? increment.Pipe?.Quantity : null,
        "Nested Coil": job.Config?.CoilCim?.IsNested === true ? "Yes" : null,

        Customer: isAdmin ? salesOrder?.Customer.Name : job.Job.CustomerName,
        "Sales Order": salesOrder?.Number,
        "Works Order": job.Job.WorksOrder?.Number,
        "Batch No": job.BatchNo.Full,
        "Stock Code": job.Job.SKU.Stockcode,
        Description: job.Job.SKU.Description,
        Material: job.Job.SKU.Product.Material.SKUDescription.Hide ? null : job.Job.SKU.Product.Material.Code
    }
    
    let list : JSX.Element[] = [];
    let i = 0;
    for (let prop in props) {
        if (!props[prop]) continue;
        list.push(
            <tr key={i++}>
                <td><b>{prop}</b></td>
                <td>{props[prop]}</td>
            </tr>);
    }


    return (
        <div>
            {increment.Deleted ? <h1 className={"alert alert-danger"}>Increment Deleted</h1> : null }
            <div>
                {increment.Pipe != null && !increment.Pipe.Pass ? <h1>Hold</h1> : null}
                {increment.Scrap != null ? <h1>Scrap</h1> : null}
                <Table borderless>
                    <tbody>
                    {list}
                    </tbody>
                </Table>
                {increment.Pipe?.Label && index != null
                    ? <h1 style={{marginBottom: "1em"}}>{increment.Pipe.Label.QtyDescriptions[index]}</h1>
                    : null}
                <QCMove increment={increment} />

            </div>
        </div>
    );
}