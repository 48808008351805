import {z} from "zod";

const confSchema = z.object({
    Api: z.string(),
    Exo: z.string(),
    Labels: z.string()
});

export type ApiConfiguration = z.infer<typeof confSchema>;

//config is created in index.html
//@ts-ignore
const Configuration = confSchema.parse(config);

export default Configuration;