import HttpClient from "../Framework/HttpClient";
import ApiConfiguration from "./Configuration";

import * as Exo from './ExoClient'

import {z} from "zod";

const lineSchema = z.object({
    Id: z.string(),
    Name: z.string(),
    Factory: z.object({
        Name: z.string()
    })
})

const jobSchema = z.object({
    Id: z.string(),
    // CreatedOn: z.coerce.date(),
    // DueDate: z.coerce.date(),
    SKU: z.object({
        Stockcode: z.string(),
        Description: z.string(),
        Length: z.number(),
        IsCoil: z.boolean(),
        Product: z.object({
            Id: z.string(),
            Description: z.string(),
            Material: z.object({
                Id: z.string(),
                Name: z.string(),
                Code: z.string(),
                SKUDescription: z.object({
                    Hide: z.boolean()
                })
            })
        })
    }),
    CustomerName: z.string().nullish(),
    SalesOrder: Exo.ExoSalesOrderSchema.nullish(),
    SalesOrderLine: Exo.ExoSalesOrderLineSchema.nullish(),
    WorksOrder: Exo.ExoWorksOrderSchema.nullish()
});

const packSizeSchema = z.object(
    {
        Quantity: z.number(),
        Description: z.string().nullish()
    }
);

const coilDimensionsSchema = z.object({
    ID: z.number(),
    OD: z.number(),
    Width: z.number()
});

const jobCoilDimensionsSchema = z.object({
    IsNested: z.boolean(),
    CoilDim: coilDimensionsSchema.nullish(),
    NestedDim: coilDimensionsSchema.nullish()
});

const jobRunSchema = z.object({
    Job: jobSchema,
    Line: lineSchema,
    StreamPosition: z.number(),
    BatchNo: z.object({
        Short: z.string(),
        Full: z.string()
    }),
    Config: z.object({
        PackSize: packSizeSchema.nullish(),
        CoilCim: jobCoilDimensionsSchema.nullish()
    })
});

const jobIncrementSchema = z.object({
    LineId: z.string(),
    JobId: z.string(),
    EventId: z.number(),
    Timestamp: z.coerce.date(),
    Deleted: z.boolean(),

    Scrap: z.object({
        Kilos: z.number().nullish()
    }).nullish(),
    
    Pipe: z.object({
        Quantity: z.number(),
        QtyOnHold: z.number().nullish(),
        Pass: z.boolean(),
        Label: z.object({
            QtyDescriptions: z.string().array()
        }),
    }).nullish(),
    
    FailReason: z.object({
        Name: z.string()
    }).nullish()
});

export type Line = z.infer<typeof lineSchema>;
export type Job = z.infer<typeof jobSchema>;
export type JobRun = z.infer<typeof jobRunSchema>;
export type JobIncrement = z.infer<typeof jobIncrementSchema>;

export default class ApiClient extends HttpClient {
    constructor() {
        super(ApiConfiguration.Api);
    }

    public async GetJob(jobId: string) : Promise<JobRun> {
        jobId = encodeURIComponent(jobId);
        return await this.Get(`api/Jobs/Run/${jobId}`, jobRunSchema) as JobRun;
    }
    
    public async GetIncrement(jobId: string, eventId: string) : Promise<JobIncrement> {
        jobId = encodeURIComponent(jobId);
        eventId = encodeURIComponent(eventId);
        return await this.Get(`api/Jobs/Run/${jobId}/Increments/${eventId}`, jobIncrementSchema) as JobIncrement;
    }

    public async MoveHoldPipe(jobId: string, eventId: string, pass: boolean) {
        let increment = await this.GetIncrement(jobId, eventId);
        let body = {
            Quantity: increment.Pipe!.QtyOnHold,
            Pass: pass,
            SourceEventId: eventId
        };
        return await this.Send("POST",
            `api/Jobs/Run/${jobId}/MoveHoldPipe`,
            body,
            jobRunSchema) as JobRun;
    }
}