import MsalClientApp, {MsalScopes} from "./MsalClientApp";
import {z} from "zod";

class HttpClient {
    private readonly baseUrl: string;

    constructor(baseUrl: string) {
        this.baseUrl = baseUrl;
    }

    public async SendCore(method: "GET" | "POST", url: string, body: {} | null)
    {
        let account= MsalClientApp.getActiveAccount();
        if (!account)
        {
            throw new Error("User is not logged in");
        }

        const token = await MsalClientApp.acquireTokenSilent({
            scopes: MsalScopes,
            account: account
        });

        let request: RequestInit = {
            method: method,
            headers: {
                Authorization: `${token.tokenType} ${token.accessToken}`,
                'Content-Type': "application/json"
            },
            body: (body ? JSON.stringify(body) : null)
        }

        let response = await fetch(`${this.baseUrl}${url}`, request);
        if (response.status >= 200 && response.status <= 299) {
            return response;
        }
        throw new Error(`${method} ${url} returned ${response.status}: ${response.statusText}. ${await response.text()}`);
    }

    public async Send<T extends z.ZodTypeAny>(method: "GET" | "POST", url: string, body: {} | null, schema: T) : Promise<any> {
        let response = await this.SendCore(method, url, body);
        return schema.parse(await response.json());
    }

    public Get<T extends z.ZodTypeAny>(url: string, schema: T) : Promise<any>
    {
        return this.Send("GET", url, null, schema);
    }
}

export default HttpClient;