
import {createBrowserRouter,} from "react-router-dom";
import App from "./App";
import PipeView, { loadPipe } from "./PipeView";
import {movePipe} from "./QCMove";

export default function CreateRouter() {
    return createBrowserRouter([
        {
            path: "/",
            element: <App />,
            children: [
                {
                    path: "j/:jobId/:eventId",
                    element: <PipeView />,
                    loader: loadPipe
                },
                {
                    path: "j/:jobId/:eventId/:action",
                    action: movePipe
                }
            ]
        },
    ]);
}