import React from 'react';
import '../App.css';
import {Authentication} from "../Authentication";

import {InteractionType} from "@azure/msal-browser";
import {AuthenticatedTemplate, UnauthenticatedTemplate, useMsalAuthentication} from "@azure/msal-react";

import {Outlet} from "react-router-dom";

export default function App() {
    useMsalAuthentication(InteractionType.Redirect);

    return (
        <div style={{ margin: "1em"}}>
            <Authentication />

            <AuthenticatedTemplate>
                <Outlet />
            </AuthenticatedTemplate>

            <UnauthenticatedTemplate>
                <p>You must login to continue!</p>
            </UnauthenticatedTemplate>
        </div>
    )
}
