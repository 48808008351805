function pad(n: number, width=2)
{
    return n.toString().padStart(width, '0');
}

declare global {
    interface Date {
        // Returns a string in the format d/mm/yyyy hh:mm:ss tt
        toDisplayDateTimeString(): string;
    }
    interface Array<T> {
        // Inserts an item at the specified index
        insert(index: number, item: T): void;
    }
}

// eslint-disable-next-line no-extend-native
Date.prototype.toDisplayDateTimeString = function() {
    let dt =`${this.getDate()}/${pad(this.getMonth() + 1)}/${this.getFullYear()}`;

    let tt = this.getHours() < 12 ? "AM" : "PM";
    let hours = this.getHours() % 12;
    if (hours === 0) {
        hours = 12;
    }
    let time = `${hours}:${pad(this.getMinutes())}`;

    return `${dt} ${time} ${tt}`;
};

// eslint-disable-next-line no-extend-native
Array.prototype.insert = function(index, item) {
    this.splice(index, 0, item);
}

export {};