import ApiClient, {JobIncrement} from "../Api/ApiClient";
import Button from "react-bootstrap/Button";
import {faTrashCan, faCheckCircle} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {ActionFunctionArgs, Form, redirect} from "react-router-dom";
import {z} from "zod";

export async function movePipe({params} : ActionFunctionArgs) {
    let pass = z.enum(["pass","scrap"]).parse(params.action);
    
    let {jobId, eventId} = params;

    let response = await new ApiClient().MoveHoldPipe(jobId!, eventId!, pass === "pass");
    return redirect(`/j/${jobId}/${response.StreamPosition}`); //Redirect to the new pipe
}

export default function QCMove(props: {increment: JobIncrement}) {
    let increment = props.increment;
    let pipe = increment.Pipe;
    if (!pipe || !pipe.QtyOnHold || pipe.QtyOnHold === 0) return null;

    let msg = pipe.Quantity > 1 ? ` ${pipe.QtyOnHold} pipes` : null;

    return (
        <Form method="POST" style={{display: "flex" }} className="qc-buttons-wrapper">
            <Button variant="success"
                    type="submit"
                    formAction="pass">
                <FontAwesomeIcon icon={faCheckCircle} size="lg"/>
                <span>Pass{msg}</span>
            </Button>
            <Button variant="danger"
                    type="submit"
                    formAction="scrap">
                <FontAwesomeIcon icon={faTrashCan} size="lg"/>
                <span>Scrap{msg}</span>
            </Button>
        </Form>
    );
}